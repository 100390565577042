import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LanguageSelect from "./LanguageSelect";
import Modal from "./Modal";
import { phone } from "phone";
import { CODE2LANG, COUNTRY_CODES } from "../constants";
import { PhoneIcon } from "@heroicons/react/20/solid";

export function PhoneMode() {
  const [simultaneous, setSimultaneous] = useState(true);

  const [yourNumber, setYourNumber] = useState("");
  const [theirNumber, setTheirNumber] = useState("");

  const [yourCountry, setYourCountry] = useState("");
  const [theirCountry, setTheirCountry] = useState("");

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const canStartCall = () => {
    const number1 = phone(yourNumber);
    const number2 = phone(theirNumber);
    return (
      number1.isValid &&
      (number2.isValid || !simultaneous) &&
      yourCountry.length > 0 &&
      theirCountry.length > 0 &&
      checked
    );
  };

  const launchConsecutiveCall = async () => {
    "https://fluxinterpreting.com/api/create-call?number=15137061124&lang1=english&lang2=spanish";
    const url = "https://fluxinterpreting.com/api/create-call";
    const data = {
      number: yourNumber,
      lang1: CODE2LANG.find((obj) => obj.code === yourCountry).lang,
      lang2: CODE2LANG.find((obj) => obj.code === theirCountry).lang,
    };

    const params = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");

    setLoading(true);
    await fetch(url + "?" + params, {
      mode: "no-cors",
    });
    setLoading(false);

    setYourNumber("");
    setTheirNumber("");
    setYourCountry("");
    setTheirCountry("");
    setChecked(false);
    setOpenModal(false);
  };

  const launchSimultaneousCall = async () => {
    const url = "https://fluxinterpreting.com/api/twoway";
    const data = {
      number1: yourNumber,
      number1_lang: CODE2LANG.find((obj) => obj.code === yourCountry).lang,
      number2: theirNumber,
      number2_lang: CODE2LANG.find((obj) => obj.code === theirCountry).lang,
    };

    const params = Object.keys(data)
      .map((key) => key + "=" + data[key])
      .join("&");

    setLoading(true);
    await fetch(url + "?" + params, {
      mode: "no-cors",
    });
    setLoading(false);

    setYourNumber("");
    setTheirNumber("");
    setYourCountry("");
    setTheirCountry("");
    setChecked(false);
    setOpenModal(false);
  };
  return (
    <div className="w-full">
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        launchCall={
          simultaneous ? launchSimultaneousCall : launchConsecutiveCall
        }
        loading={loading}
      />

	  {/*<div className="flex flex-col space-y-2 mt-6">
	<p className="text-gray-800">Select interpretation mode</p>
        <div className="flex">
          <input
            type="radio"
            name="hs-default-radio"
            className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            id="hs-default-radio"
            checked={!simultaneous}
            onClick={() => setSimultaneous(false)}
          />
          <label for="hs-default-radio" className="text-base ms-2">
            Consecutive
          </label>
        </div>

        <div className="flex">
          <input
            type="radio"
            name="hs-default-radio"
            className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            id="hs-checked-radio"
            checked={simultaneous}
            onClick={() => setSimultaneous(true)}
          />
          <label for="hs-checked-radio" className="text-base ms-2">
            Simultaneous
          </label>
        </div>
      </div>*/}

      {!simultaneous && (
        <div className="flex flex-col mt-6">
          <div className="flex flex-col w-full">
            <p className="text-gray-800">Enter your number</p>
            <PhoneInput
              onChange={setYourNumber}
              className="mt-2 w-full custom-phone-input"
              value={yourNumber}
              onlyCountries={COUNTRY_CODES}
              country="us"
            />
          </div>

          <div className="flex flex-col w-full mt-3">
            <p className="text-gray-800">Select your language</p>
            <LanguageSelect
              selected={yourCountry}
              setSelected={setYourCountry}
              options={CODE2LANG}
            />
          </div>

          <div className="flex flex-col w-full mt-3">
            <p className="text-gray-800">Select their language</p>
            <LanguageSelect
              selected={theirCountry}
              setSelected={setTheirCountry}
              options={CODE2LANG}
            />
          </div>
        </div>
      )}

      {simultaneous && (
        <div className="flex flex-col mt-6">
          <div className="flex flex-col w-full">
            <p className="text-gray-800">
              Enter <b>your</b> number
            </p>
            <PhoneInput
              onChange={setYourNumber}
              className="mt-2 w-full custom-phone-input"
              value={yourNumber}
              onlyCountries={COUNTRY_CODES}
              country="us"
            />
          </div>

          <div className="flex flex-col w-full mt-2">
            <p className="text-gray-800">
              Select <b>your</b> language
            </p>
            <LanguageSelect
              selected={yourCountry}
              setSelected={setYourCountry}
              options={CODE2LANG}
            />
          </div>

          <div className="flex flex-col w-full mt-2">
            <p className="text-gray-800">
              Enter <b>their</b> number
            </p>
            <PhoneInput
              onChange={setTheirNumber}
              className="mt-2 w-full custom-phone-input"
              value={theirNumber}
              onlyCountries={COUNTRY_CODES}
              country="us"
            />
          </div>

          <div className="flex flex-col w-full mt-2">
            <p className="text-gray-800">
              Select <b>their</b> language
            </p>
            <LanguageSelect
              selected={theirCountry}
              setSelected={setTheirCountry}
              options={CODE2LANG}
            />
          </div>
        </div>
      )}

      <div className="flex items-center mt-8">
        <div className="flex">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
        </div>
        <div className="ms-3">
          <label htmlFor="remember-me" className="text-sm dark:text-white">
            I agree that my phone calls may be reviewed by the TranslateMyCall
            team to improve the service.
          </label>
        </div>
      </div>

      <div className="mt-8">
        <button
          className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-700 text-white hover:bg-blue-800 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none shadow-sm"
          disabled={!canStartCall()}
          onClick={() => {
            setOpenModal(true);
            window.gtag('event', 'click', {
              event_category: 'button',
              event_label: 'launch_call_button',
            });
          }}
        >
          <PhoneIcon className="h-6 w-6" />
          Launch call
        </button>
      </div>
    </div>
  );
}
